/* eslint-disable unicorn/numeric-separators-style */
/* eslint/restrict-template-expressions */
/* @typescript-eslint/restrict-template-expressions */
/* @typescript-eslint/no-unsafe-member-access */
/* @typescript-eslint/no-unsafe-call */

import Qs from 'qs';

const unassignedParams = Qs.stringify(
  {
    filters: {
      event_tech_statuses: {
        '0': {
          status: ['in_process', 'submitted'],
          technician_status: [''],
        },
      },
    },
    page: 1,
    per_page: 10000,
    list_org_events: false,
    sort_dir: 'asc',
    sort_col: 'created_at',
  },
  {
    arrayFormat: 'brackets',
    encode: false,
  },
);

const assignedParams = Qs.stringify(
  {
    filters: {
      technician_status: [
        'assigned',
        'accepted',
        'declined',
        'en_route',
        'arrived',
        'repaired',
      ],
      status: ['submitted', 'repaired', 'in_process', 'completed'],
    },
    page: 1,
    per_page: 10000,
    list_org_events: false,
    sort_dir: 'asc',
    sort_col: 'created_at',
  },
  {
    arrayFormat: 'brackets',
    encode: false,
  },
);

export const LOGGED_USER = '';

export const COMPANY_NAME = 'reach24';
export const TECHNICIANS_PATH = 'v3/technicians?include_locations=true';
export const USER_INFO_PATH = 'v3/me';
export const UN_ASSIGNED_EVENTS_PATH = `v3/service_events/jpb_events_list?${unassignedParams}`;
export const ASSIGNED_EVENTS_PATH = `v3/service_events/jpb_events_list?${assignedParams}`;
export const ASSIGN_TECHNICIANS_PATH = 'v3/service_events';
export const CONFIRM_EVENT = 'v3/service_events';
export const TECHNICIAN_SHIFT_PATH = 'v3/technicians/shifts';
